import { customerApi } from '@redux/rtk/api'

const addressBookApi = customerApi.injectEndpoints({
    endpoints: (builder) => ({
        getAddressBook: builder.query({
            query: (params) => ({
                url: 'customers/address_books/',
                method: 'GET',
                params,
            }),
            providesTags: (result) => {
                return result
                    ? [
                          ...result?.results?.map(({ id }) => ({ type: 'AddressBook', id })),
                          { type: 'AddressBook', id: 'LIST' },
                      ]
                    : [{ type: 'AddressBook', id: 'LIST' }]
            },
        }),
    }),
    overrideExisting: true,
})

export const { useGetAddressBookQuery } = addressBookApi
