import { calculateMarginPercent, calculateMarginPriceNet, calculateSellingPriceGross } from '@common/utils/helpers'
import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
    orders: null,
    margin: 0,
}

const tarificalSlice = createSlice({
    name: 'tarifical',
    initialState,
    reducers: {
        initBaseOrdersPrice: (state, action) => {
            state.orders = action.payload['services']
        },
        setMargin: (state, action) => {
            state.margin = action.payload
        },
        setOrdersPrice: (state, action) => {
            const { orderId, key, value } = action.payload

            const orderIndex = state.orders.findIndex((order) => order.id === orderId)

            if (orderIndex !== -1) {
                state.orders[orderIndex][key] = value

                if (key === 'margin_percent') {
                    const { buying_price_net, margin_percent, service_price } = state.orders[orderIndex]

                    state.orders[orderIndex]['margin_price_net'] = calculateMarginPriceNet(buying_price_net, value)

                    state.orders[orderIndex]['selling_price_gross'] = calculateSellingPriceGross(buying_price_net, margin_percent, service_price.tax_percent)
                } else if (key === 'margin_price_net') {
                    const { buying_price_net, service_price, margin_percent } = state.orders[orderIndex]

                    state.orders[orderIndex]['margin_percent'] = calculateMarginPercent(buying_price_net, value)

                    state.orders[orderIndex]['selling_price_gross'] = calculateSellingPriceGross(buying_price_net, margin_percent, service_price.tax_percent)
                } else if (key === 'selling_price_gross') {
                    // TODO
                }
            }
        },

        setMarginOnAllOrders: (state, action) => {
            state.orders.forEach((order) => {
                order['margin_percent'] = action.payload
                order['margin_price_net'] = calculateMarginPriceNet(order.buying_price_net, action.payload)
                order['selling_price_gross'] = calculateSellingPriceGross(order.buying_price_net, action.payload, order.service_price.tax_percent)
            })
        },
    },
})

export const { initBaseOrdersPrice, setOrdersPrice, setMargin, setMarginOnAllOrders } = tarificalSlice.actions
export default tarificalSlice.reducer

// ROOT SELECTOR
const tarificalSelector = (state) => state.tarifical

export const selectOrdersServicePrices = createSelector(tarificalSelector, (tarifical) => tarifical.orders || [])
export const selectMargin = createSelector(tarificalSelector, (tarifical) => tarifical.margin)
