import { createSelector, createSlice } from '@reduxjs/toolkit'
import { resetAddDataOrderForm, setAddDataOrderForm } from '@redux/common'

const initialState = {
    addFields: {
        address: [
            {
                name: 'completion_date',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY:HH:mm',
            },
        ],
        complements: [
            {
                name: 'derogation_to_be_provided',
                label: 'Dérogation à prévoir',
                component: 'boolean',
                labelCol: 24,
            },
            {
                name: 'derogation_file',
                label: 'Fichier à transférer',
                labelCol: 24,
                component: 'file',
                maxSize: 4,
                acceptedTypes: [
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'image/webm',
                    'application/pdf',
                ],
                multiple: false,
            },
            {
                name: 'police_summons',
                label: 'Convocation police',
                component: 'boolean',
                labelCol: 24,
            },
            {
                name: 'comments',
                label: 'Commentaires',
                component: 'textaera',
                rows: 2,
                labelCol: 24,
            },
        ],
    },
    editFields: {
        address: [
            {
                name: 'completion_date',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY:HH:mm',
            },
        ],
        complements: {
            derogation: [
                {
                    name: 'derogation_to_be_provided',
                    label: 'Dérogation à prévoir',
                    component: 'boolean',
                    labelCol: 24,
                },
            ],
            files: [
                {
                    name: 'derogation_file',
                    label: 'Fichiers à transférer',
                    labelCol: 24,
                    component: 'file',
                    maxSize: 4,
                    acceptedTypes: [
                        'image/png',
                        'image/jpeg',
                        'image/jpg',
                        'image/webm',
                        'application/pdf',
                    ],
                    multiple: false,
                },
            ],
            police: [
                {
                    name: 'police_summons',
                    label: 'Convocation police',
                    component: 'boolean',
                    labelCol: 24,
                },
                {
                    name: 'comments',
                    label: 'Commentaires',
                    component: 'textaera',
                    rows: 2,
                    labelCol: 24,
                },
            ],
        },
    },
    addData: null,
}

const proceduresSlice = createSlice({
    name: 'procedures',
    initialState,
    reducers: {
        setAddDataForm: setAddDataOrderForm,
        resetAddDataForm: resetAddDataOrderForm,
    },
})

export const { setAddDataForm, resetAddDataForm } = proceduresSlice.actions
export default proceduresSlice.reducer

// Root selector
const proceduresSelector = (state) => state.procedures

export const selectProceduresFieldsAdd = createSelector(
    proceduresSelector,
    (proceduresState) => proceduresState.addFields
)

export const selectProceduresFieldsEdit = createSelector(
    proceduresSelector,
    (proceduresState) => proceduresState.editFields
)

export const selectProcedureAddData = createSelector(
    proceduresSelector,
    (proceduresState) => proceduresState.addData
)
