import LogoLong from '@common/assets/funovia-long-white.png'
import LogoShort from '@common/assets/funovia-short-white.png'
import { ANTD_CONFIG, POLLING_INTERVAL } from '@common/constants'
import { Footer, Header, LayoutDashboard as LayoutDashboardLib, Sidebar } from '@libs/ke-library'
import { useRouter } from 'next/router'
import { ALL_ROUTES } from '@routes/index'
import { useGetMeQuery } from '@redux/features/auth'
import { Spin } from 'antd'
import React from 'react'

import Breadcrumbs from './Breadcrumbs'
import SearchGlobal from './SearchGlobal'
import UserBadge from './UserBadge'

const CustomerLayout = ({ children }) => {
    const router = useRouter()

    const { isSupervisor, isLoading } = useGetMeQuery(
        {},
        {
            pollingInterval: POLLING_INTERVAL,
            selectFromResult: ({ data, isLoading }) => ({
                isSupervisor: data?.agent?.is_supervisor,
                isLoading: isLoading,
            }),
        }
    )

    const filteredRoutes = React.useMemo(() => {
        if (!isSupervisor) {
            return ALL_ROUTES.filter((route) => !route.path.includes('my-business'))
        } else {
            return ALL_ROUTES
        }
    }, [isSupervisor])

    // Wait isSupervisor result is before render else routes will be filtered before isSupervisor is get
    if (isLoading) {
        return (
            <Spin
                style={{
                    position: 'absolute',
                    top: '50%',
                    right: '50%',
                    transform: 'translate(50%,-50%)',
                }}
                tip="chargement"
            />
        )
    }

    return (
        <LayoutDashboardLib
            sidebar={
                <Sidebar
                    logos={[LogoLong, LogoShort]}
                    collapseIconClose={<i className="fa-light fa-circle-play" />}
                    collapseIconOpen={<i className="fa-light fa-circle-play" />}
                    bottomItems={[]}
                />
            }
            header={
                <Header
                    left={<Breadcrumbs />}
                    right={
                        <>
                            <SearchGlobal />
                            <UserBadge />
                        </>
                    }
                />
            }
            footer={<Footer author="kienso.fr" />}
            routes={filteredRoutes}
            router={router}
            themeCl={ANTD_CONFIG}
        >
            <>
                <div className="gradient"></div>
                {children}
            </>
        </LayoutDashboardLib>
    )
}

export default CustomerLayout
