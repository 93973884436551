import { resetAddressBook } from '@redux/features/address-book'
import { customerApi } from '@redux/rtk/api'

import { resetAddDataForm, resetEditDataForm, setAddDataForm } from './convoySlice'
import * as Sentry from '@sentry/nextjs'

const convoysApi = customerApi.injectEndpoints({
    endpoints: (builder) => ({
        getConvoyPlanning: builder.query({
            query: (cursorDate) => ({
                url: `customers/service_convoys_planing/`,
                method: 'GET',
                params: {
                    start: cursorDate.start,
                    end: cursorDate.end,
                },
            }),
        }),
        bookConvoy: builder.mutation({
            query: (body) => ({
                url: `customers/service_convoys/`,
                method: 'POST',
                body: body,
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled

                    dispatch(
                        setAddDataForm({
                            convoyId: data.id,
                            availability: data.availability,
                            funeral: data.funeral,
                            bury_date: data.bury_date,
                            bury_date_am_pm: data.bury_date_am_pm,
                        })
                    )
                } catch (error) {
                    Sentry.captureException(error)

                }
            },
        }),
        getConvoy: builder.query({
            query: (id) => ({
                url: `customers/service_convoys/${id}/`,
                method: 'GET',
            }),
        }),

        updateConvoy: builder.mutation({
            query: ({ data, id }) => ({
                url: `customers/service_convoys/${id}/`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Funeral' }],

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    dispatch(resetAddressBook())

                    dispatch(resetAddDataForm())
                    dispatch(resetEditDataForm())
                } catch (err) { }
            },
        }),
        deleteConvoy: builder.mutation({
            query: (id) => ({
                url: `customers/service_convoys/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Funeral', id: arg.id }],
        }),
        deleteConvoyFile: builder.mutation({
            query: (id) => ({
                url: `customers/medias/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Funeral', id: arg.id }],
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetConvoyPlanningQuery,
    useGetConvoyQuery,
    useBookConvoyMutation,
    useUpdateConvoyMutation,
    useDeleteConvoyMutation,
    useDeleteConvoyFileMutation,
} = convoysApi
