import { AUTHORITY_RELATION, FUNERAL_TYPES, CEREMONY_TYPES } from '@common/constants'
import { resetAddDataOrderForm, setAddDataOrderForm } from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
    addFields: {
        autorityPower: [
            {
                name: 'authority_firstname',
                label: 'Prénom',
                component: 'text',
            },
            {
                name: 'authority_lastname',
                label: 'Nom',
                component: 'text',
            },
            {
                name: 'authority_phone',
                label: 'Tél.',
                component: 'phone',
            },
            {
                name: 'authority_relationship',
                label: 'Lien de parenté',
                placeholder: 'Selectionner un lien',
                component: 'select',
                options: AUTHORITY_RELATION,
            },
        ],

        bury: {
            info_left: [
                {
                    name: 'bury_with_family',
                    label: 'Présence de la famille',
                    component: 'boolean',
                    labelCol: 24,
                },
            ],
            info_right: [
                {
                    name: 'bury_with_police',
                    label: 'Présence de la police',
                    component: 'boolean',
                    labelCol: 24,
                },
            ],
            right: {
                date: [
                    {
                        name: 'bury_date',
                        label: 'Date',
                        component: 'date',
                        placeholder: 'Selectionner une date',
                        format: 'DD/MM/YYYY',
                        disabled: true,
                    },

                    {
                        name: 'bury_start_time',
                        label: 'MB',
                        component: 'time',
                        placeholder: 'Selectionner une heure',
                        format: 'HH:mm',
                    },
                    {
                        name: 'bury_end_time',
                        label: 'Départ',
                        component: 'time',
                        placeholder: 'Selectionner une heure',
                        format: 'HH:mm',
                    },
                ],
            },
        },

        ceremony: [
            {
                name: 'ceremony_datetime',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY HH:mm',
            },
            {
                name: 'ceremony_type',
                component: 'radio',
                options: CEREMONY_TYPES,
            },
        ],

        funeral: [
            {
                name: 'funeral_datetime',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY HH:mm',
            },
            {
                name: 'funeral_type',
                label: 'Type de cérémonie',
                component: 'radio',
                options: FUNERAL_TYPES,
            },
        ],
        files: [
            {
                name: 'medias',
                component: 'file',
                labelCol: 24,
                maxSize: 4, // MB
                acceptedTypes: [
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'image/webm',
                    'application/pdf',
                ],
            },
        ],
    },
    editFields: {
        autorityPower: [
            {
                name: 'authority_firstname',
                label: 'Prénom',
                component: 'text',
            },
            {
                name: 'authority_lastname',
                label: 'Nom',
                component: 'text',
            },
            {
                name: 'authority_phone',
                label: 'Tél.',
                component: 'phone',
            },
            {
                name: 'authority_relationship',
                label: 'Lien de parenté',
                placeholder: 'Selectionner un lien',
                component: 'select',
                options: AUTHORITY_RELATION,
            },
        ],

        bury: {
            left: [
                {
                    name: 'bury_with_family',
                    label: 'Présence de la famille',
                    component: 'boolean',
                    labelCol: 24,
                },
                {
                    name: 'bury_with_police',
                    label: 'Présence de la police',
                    component: 'boolean',
                    labelCol: 24,
                },
            ],
            right: {
                date: [
                    {
                        name: 'bury_date',
                        label: 'Date',
                        component: 'date',
                        placeholder: 'Selectionner une date',
                        format: 'DD/MM/YYYY',
                        disabled: true,
                    },

                    {
                        name: 'bury_start_time',
                        label: 'MB',
                        component: 'time',
                        placeholder: 'Selectionner une heure',
                        format: 'HH:mm',
                    },
                    {
                        name: 'bury_end_time',
                        label: 'Départ',
                        component: 'time',
                        placeholder: 'Selectionner une heure',
                        format: 'HH:mm',
                    },
                ],
            },
        },

        ceremony: {
            left: [
                {
                    name: 'ceremony_type',
                    component: 'radio',
                    options: CEREMONY_TYPES,
                },
            ],
            right: [
                {
                    name: 'ceremony_datetime',
                    label: 'Date et heure',
                    component: 'datetime',
                    placeholder: 'Selectionner une date',
                    format: 'DD/MM/YYYY HH:mm',
                },
            ],
        },

        funeral: {
            left: [
                {
                    name: 'funeral_type',
                    label: 'Type de cérémonie',
                    component: 'radio',
                    options: FUNERAL_TYPES,
                },
            ],
            right: [
                {
                    name: 'funeral_datetime',
                    label: 'Date et heure',
                    component: 'datetime',
                    placeholder: 'Selectionner une date',
                    format: 'DD/MM/YYYY:HH:mm',
                },
            ],
        },

        files: [
            {
                name: 'medias',
                component: 'file',
                labelCol: 24,
                maxSize: 4, // MB
                acceptedTypes: [
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'image/webm',
                    'application/pdf',
                ],
            },
        ],
    },
    editData: null,
    addData: null,
    bookingDate: null,
}

const convoySlice = createSlice({
    name: 'convoy',
    initialState,
    reducers: {
        setAddDataForm: setAddDataOrderForm,
        setEditDataForm: (state, action) => {
            state.editData = action.payload
        },
        resetAddDataForm: resetAddDataOrderForm,
        resetEditDataForm: (state) => {
            state.editData = null
        },
        setBookingDate: (state, action) => {
            state.bookingDate = action.payload
        },
    },
})

export const {
    setAddDataForm,
    setEditDataForm,
    resetAddDataForm,
    resetEditDataForm,
    setBookingDate,
} = convoySlice.actions
export default convoySlice.reducer

// Root selector
const convoySelector = (state) => state.convoy

export const selectConvoysFieldsAdd = createSelector(
    convoySelector,
    (convoyState) => convoyState.addFields
)

export const selectConvoyAddData = createSelector(
    convoySelector,
    (convoyState) => convoyState.addData
)

export const selectConvoysFieldsEdit = createSelector(
    convoySelector,
    (convoyState) => convoyState.addFields
)

export const selectConvoyEditData = createSelector(
    convoySelector,
    (convoyState) => convoyState.editData
)

export const selectConvoyInformationAddData = createSelector(
    selectConvoyAddData,
    (selectConvoyAddDataState) =>
        _.omit(selectConvoyAddDataState, [
            'staff_hearse_number',
            'staff_master_of_ceremony_number',
            'staff_bearer_number',
            'staff_vehicle_number',
        ])
)
