import { setIsAuth } from '@redux/features/auth'
import { persistor } from '@redux/store'
import { createListenerMiddleware, isAnyOf, isRejectedWithValue } from '@reduxjs/toolkit'
import { notification } from 'antd'
import _ from 'lodash'
export const listenerMiddleware = createListenerMiddleware()
import {
    setActualFilters as setActualAgenciesFilter,
    setPagination as setActualAgenciesPagination,
} from '@redux/features/my-business/agencies'
import {
    setActualFilters as setActualAgentsFilter,
    setPagination as setActualAgentsPagination,
} from '@redux/features/users/agents'
import {
    setActualFilters as setActualFuneralsFilter,
    setPagination as setActualFuneralsPagination,
} from '@redux/features/funeral'
const isAFiltersAction = isAnyOf(
    setActualAgenciesFilter,
    setActualAgentsFilter,
    setActualFuneralsFilter
)

listenerMiddleware.startListening({
    matcher: isAFiltersAction,
    effect: (action, listenerApi) => {
        switch (action.type) {
            case 'agencies/setActualFilters':
                listenerApi.dispatch(setActualAgenciesPagination(1))
                break
            case 'agents/setActualFilters':
                listenerApi.dispatch(setActualAgentsPagination(1))
                break
            case 'funerals/setActualFilters':
                listenerApi.dispatch(setActualFuneralsPagination(1))
                break
            default:
                break
        }
    },
})

/**
 * Show notification on error captured
 * TODO: setup sentry here
 */
export const rtkQueryErrorLogger = (api) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if (action.payload?.status === 401) {
            localStorage.removeItem('funovia-token')
            persistor.flush()
            persistor.purge()
            api.dispatch(setIsAuth(false))
        } else {
            if (!_.isNil(action) && action?.payload && action?.payload?.data) {
                for (const [key, value] of Object.entries(action?.payload?.data)) {
                    notification.error({
                        placement: 'top',
                        bottom: 50,
                        duration: 3,
                        message: value?.[0] || 'Une erreur est survenue',
                    })
                }
            }
        }
    }

    return next(action)
}
