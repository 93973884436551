import { persistedRootReducer } from '@redux/reducers'
import { customerApi } from '@redux/rtk/api'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { persistStore } from 'redux-persist'

import { listenerMiddleware, rtkQueryErrorLogger } from './middlewares/listener'

const middlewares = [rtkQueryErrorLogger]

const store = configureStore({
    reducer: persistedRootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        })
            .prepend(listenerMiddleware.middleware)
            .concat(customerApi.middleware)
            .concat(middlewares),
})

setupListeners(store.dispatch)

const persistor = persistStore(store)

export { store, persistor }
