import { ADMISSION_TO_BE_SIGNED } from '@common/constants'
import { resetAddDataOrderForm, setAddDataOrderForm } from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
    addFields: {
        start: [
            {
                name: 'start_at',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY HH:mm',
            },
        ],

        addition_information: [
            {
                name: 'additional_information',
                label: 'Informations complémentaires',
                component: 'textaera',
                rows: 2,
                labelCol: 24,
            },
        ],

        contact: [
            {
                name: 'contact_firstname',
                label: 'Prénom',
                component: 'text',
                placeholder: 'John',
            },
            {
                name: 'contact_lastname',
                label: 'Nom',
                placeholder: 'Doe',
                component: 'text',
            },
            {
                name: 'contact_phone',
                label: 'Tél.',
                component: 'phone',
            },
            {
                name: 'admission_to_be_signed',
                label: 'Demande d’admission à signer',
                component: 'radio',
                options: ADMISSION_TO_BE_SIGNED,
                labelCol: 24,
            },
        ],
        files: [
            {
                name: 'medias',
                component: 'file',
                maxSize: 4,
                acceptedTypes: [
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'image/webm',
                    'application/pdf',
                ],
                multiple: true,
            },
        ],
    },
    editFields: {
        start: [
            {
                name: 'start_at',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY HH:mm',
            },
        ],
        addition_information: [
            {
                name: 'additional_information',
                label: 'Informations complémentaires',
                component: 'textaera',
                rows: 2,
                labelCol: 24,
            },
        ],
        arrival: [
            {
                name: 'arrival_address',
                label: 'Adresse',
                component: 'addressBook',
            },
        ],
        contact: [
            {
                name: 'contact_firstname',
                label: 'Prénom',
                component: 'text',
                placeholder: 'John',
            },
            {
                name: 'contact_lastname',
                label: 'Nom',
                placeholder: 'Doe',
                component: 'text',
            },
            {
                name: 'contact_phone',
                label: 'Tél.',
                component: 'phone',
            },
            {
                name: 'admission_to_be_signed',
                label: 'Demande d’admission à signer',
                component: 'radio',
                options: ADMISSION_TO_BE_SIGNED,
                labelCol: 24,
            },
        ],

        files: [
            {
                name: 'medias',
                component: 'file',
                maxSize: 4,
                acceptedTypes: [
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'image/webm',
                    'application/pdf',
                ],
                multiple: true,
            },
        ],
    },
    addData: null,
}

const transfersSlice = createSlice({
    name: 'transfers',
    initialState,
    reducers: {
        setAddDataForm: setAddDataOrderForm,
        resetAddDataForm: resetAddDataOrderForm,
    },
})

export const { setAddDataForm, resetAddDataForm } = transfersSlice.actions
export default transfersSlice.reducer

// Root selector
const transfersSelector = (state) => state.transfers

export const selectTransfersFieldsAdd = createSelector(
    transfersSelector,
    (transfersState) => transfersState.addFields
)

export const selectTransfersFieldsEdit = createSelector(
    transfersSelector,
    (transfersState) => transfersState.editFields
)

export const selectTransfersAddData = createSelector(
    transfersSelector,
    (transfersState) => transfersState.addData
)
