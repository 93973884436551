// This files contain common reducers

// All reducer are prefixed with the current action and the type of page (list, add, edit, delete)

// Example: <action><type><reducer>

// For using this common reducer. Your slice need to be the same or create a second function

export const setListPagination = (state, action) => {
    state.pagination.page = action.payload

    return state
}

export const setListActualFilter = (state, action) => {
    state.filters.actual = action.payload

    return state
}

export const resetListActualFilter = (state, action) => {
    state.filters.actual = {}

    return state
}

export const removeListFieldInFilter = (state, action) => {
    delete state.actualFilters[action.payload]

    return state
}

// Il will be used for add data form to display it in summary
// Usage for the 4 order
export const setAddDataOrderForm = (state, action) => {
    state.addData = action.payload

    return state
}

export const resetAddDataOrderForm = (state, action) => {
    state.addData = null

    return state
}

export const setTotalInPagination = (state, action) => {
    state.pagination.total = action.payload

    return state
}
