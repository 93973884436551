import { ANTD_CONFIG } from '@common/constants'
import withAuthentication from '@middlewares/withAuthentication'
import { resetAddressBook } from '@redux/features/address-book'
import { resetAddDataForm } from '@redux/features/services/convoy'
import { persistor, store } from '@redux/store'
import { ConfigProvider } from 'antd'
import { useRouter } from 'next/router'
import React from 'react'
import { Provider, useDispatch } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import '@styles/index.scss'

const ProtectRoute = withAuthentication(({ children }) => {
    // We create a ProtectRoute component to wrap only here all pages else we need to set withAuthentication on each page

    const { events, pathname } = useRouter()

    const dispatch = useDispatch()

    // Handle router change and reset address book and convoy form because if we change page and come back to convoy form, we need to reset the form
    // When we using step the page don't change so we can't reset form inside the page
    // All reset in any page of customer app need to be set here
    React.useEffect(() => {
        const handleRouteChange = (url, { shallow }) => {
            // dispatch(resetAddressBook())

            if (pathname === '/orders/convoy/create') {
                dispatch(resetAddressBook())
                dispatch(resetAddDataForm())
            }
        }

        events.on('routeChangeComplete', handleRouteChange)

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            events.off('routeChangeComplete', handleRouteChange)
        }
    }, [events, dispatch, pathname])

    return children
})

const FunoviaCustomersWebApplication = ({ Component, pageProps }) => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConfigProvider {...ANTD_CONFIG}>
                    <ProtectRoute>
                        <Component {...pageProps} />
                    </ProtectRoute>
                </ConfigProvider>
            </PersistGate>
        </Provider>
    )
}
export default FunoviaCustomersWebApplication
