import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
    filter: {
        isOpen: false,
    },
    addressBook: {
        isOpen: false,
        key: null, // key of the address book to be opened
    },

    order: {
        isOpen: false,
        type: '',
        id: null,
    },
}

const drawerSlice = createSlice({
    name: 'drawer',
    initialState,
    reducers: {
        setFilterOpened(state, action) {
            state.filter.isOpen = action.payload
        },

        setAddressBookOpened(state, action) {
            state.addressBook.isOpen = action.payload
        },

        setAddressBookKey(state, action) {
            state.addressBook.key = action.payload
        },
        setOrderOpened(state, action) {
            state.order = {
                isOpen: action.payload.isOpen,
                type: action.payload.type,
                id: action.payload.id,
            }
        },
    },
})

export const { setFilterOpened, setAddressBookOpened, setAddressBookKey, setOrderOpened } =
    drawerSlice.actions
export default drawerSlice.reducer

// Root selector
const drawerSelector = (state) => state.drawer

export const selectFilterDrawerOpened = createSelector(
    drawerSelector,
    (drawerState) => drawerState.filter.isOpen
)

export const selectAddressBookDrawerOpened = createSelector(
    drawerSelector,
    (drawerState) => drawerState.addressBook.isOpen
)

export const selectAddressBookDrawerKey = createSelector(
    drawerSelector,
    (drawerState) => drawerState.addressBook.key
)

export const selectOrderDrawer = createSelector(drawerSelector, (drawerState) => drawerState.order)
