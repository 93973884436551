import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
    editFields: {
        informations: [
            {
                name: 'name',
                label: 'Nom',
                component: 'text',
            },
            {
                name: 'email',
                label: 'Email',
                component: 'text',
            },
            {
                name: 'phone',
                label: 'Téléphone',
                component: 'phone',
            },

            {
                name: 'siret',
                label: 'SIRET',
                component: 'text',
            },
        ],
        address: [
            {
                name: 'address',
                component: 'address',
                nestedFields: ['address', 'address_2', 'city', 'zipcode'],
            },
        ],
    },
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {},
})

export const {} = settingsSlice.actions
export default settingsSlice.reducer

// Root selector
const settingsSelector = (state) => state.settings

export const selectSettingsEditFields = createSelector(
    settingsSelector,
    (settingsState) => settingsState.editFields
)
