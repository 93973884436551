import { Button, Space, Tooltip, Typography } from 'antd'
import Image from 'next/image'
import React from 'react'

import Flex from '../../blocs/Flex'

const TooltipUser = ({ avatarSrc, fullName, onClick }) => {
    return (
        <Tooltip title="Mon compte">
            <Button href="#" className="layoutUserBadge" onClick={onClick}>
                <Space align="center">
                    {avatarSrc ? (
                        <Image width={40} height={40} src={avatarSrc} alt="Photo de profil" />
                    ) : (
                        <></>
                    )}
                    <Flex direction="column" align="start">
                        <Typography.Title level={4}>{fullName}</Typography.Title>
                    </Flex>
                </Space>
            </Button>
        </Tooltip>
    )
}
export default TooltipUser
