import { resetListActualFilter } from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import _ from 'lodash'

const initialState = {
    filters: {
        fields: [
            {
                name: 'agency',
                label: 'Agence',
                component: 'select',
                placeholder: 'Selectionner une agence',
                options: [
                    {
                        value: 'Toutes les agences',
                        key: null,
                    },
                ],
                withStatus: false,
            },
            {
                name: 'range_stats',
                component: 'dateRange',
                label: 'Période',
                placeholder: ['Du', 'au'],
                format: 'DD/MM/YYYY',
                withStatus: false,
            },
        ],
        actual: {},
    },
}

const statistiscalsSlice = createSlice({
    name: 'statisticals',
    initialState,
    reducers: {
        setActualFilters: (state, action) => {
            state.filters.actual = action.payload
        },
        resetActualFilters: (state, action) => {
            state.filters.actual = {
                agency: '',
                start: dayjs().startOf('week').format('YYYY-MM-DD'),
                end: dayjs().endOf('week').format('YYYY-MM-DD'),
                range_stats: null,
            }
        },

        setOptionsToAgenciesField: (state, action) => {
            const agencyField = state.filters.fields.find((field) => field.name === 'agency')

            if (!_.isNil(agencyField)) {
                // Here we call push method because we have a default options who is "Toutes les agences"
                agencyField.options.push(...action.payload)
            }
        },
    },
})

export const { setActualFilters, resetActualFilters, setOptionsToAgenciesField } =
    statistiscalsSlice.actions
export default statistiscalsSlice.reducer

// ROOT SELECTOR
const statisticalsSelector = (state) => state.statisticals

export const selectStatisticalsFieldsFilters = createSelector(
    statisticalsSelector,
    (statisticalState) => statisticalState.filters.fields
)

export const selectStatisticalActualFilters = createSelector(
    statisticalsSelector,
    (statisticalState) => _.omit(statisticalState.filters.actual, ['range_stats'])
)
