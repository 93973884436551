import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {}

const select = createSlice({
    name: 'select',
    initialState,
    reducers: {},
})

// export const {} = select.actions
export default select.reducer
