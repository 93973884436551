export const ALL_ROUTES = [
    {
        icon: <i className="fa-light fa-gauge" />,
        label: 'Tableau de bord',
        path: '/',
    },
    {
        icon: <i className="fa-light fa-folder" />,
        label: 'Obsèques',
        path: '/funerals',
        children: [
            {
                type: 'group',
                label: 'Obsèques',
                path: '/funerals',
                children: [
                    {
                        path: '/funerals/',
                        label: 'Liste',
                    },
                    {
                        path: '/funerals/archived',
                        label: 'Terminées',
                    },
                    {
                        path: '/funerals/planning',
                        label: 'Planning',
                    },
                ],
            },
        ],
    },
    {
        icon: <i className="fa-light fa-people-group" />,
        label: 'Services',
        path: '/orders/transfers',
        children: [
            {
                type: 'group',
                label: 'Services',
                path: '/orders',
                children: [
                    {
                        path: '/orders/transfers',
                        label: 'Transferts',
                    },
                    {
                        path: '/orders/procedures',
                        label: 'Démarches',
                    },
                    {
                        path: '/orders/embalmings',
                        label: 'Thanatopraxies',
                    },
                    {
                        path: '/orders/convoys',
                        label: 'Convois',
                    },
                ],
            },
        ],
    },
    {
        icon: <i className="fa-light fa-shop" />,
        label: 'Mon entreprise',
        path: '/my-business/statisticals',
        children: [
            {
                type: 'group',
                label: 'Mon Entreprise',
                path: '/my-business',
                children: [
                    {
                        path: '/my-business/statisticals',
                        label: 'Statistiques',
                    },
                    {
                        path: '/my-business/settings',
                        label: 'Entreprise',
                    },
                    {
                        path: '/my-business/agencies',
                        label: 'Agences',
                    },
                    {
                        path: '/my-business/agents',
                        label: 'Conseillers',
                    },
                    {
                        path: '/my-business/pricing',
                        label: 'Gestion tarifaire',
                    },
                ],
            },
        ],
    },
]

export const BREADCRUMBS = {
    '/': [
        {
            label: 'Tableau de bord',
            path: '/',
        },
    ],
    '/funerals': [
        {
            label: 'Obsèques',
            path: '/funerals',
        },
        {
            label: 'Liste',
            path: '/funerals',
        },
    ],
    '/funerals/planning': [
        {
            label: 'Obsèques',
            path: '/funerals',
        },
        {
            label: 'Planning',
            path: '/funerals/planning',
        },
    ],
    '/funerals/create': [
        {
            label: 'Obsèques',
            path: '/funerals',
        },
        {
            label: 'Création',
            path: '/funerals/create',
        },
    ],
    '/funerals/[id]': [
        {
            label: 'Obsèques',
            path: '/funerals',
        },
    ],
    '/funerals/archived': [
        {
            label: 'Obsèques',
            path: '/funerals',
        },
        {
            label: 'Terminées',
            path: '/funerals/archived',
        },
    ],
    '/orders/transfers': [
        {
            label: 'Services',
            path: '/orders/transfers',
        },
        {
            label: 'Transferts',
            path: '/orders/transfers',
        },
    ],
    '/orders/transfers/create': [
        {
            label: 'Services',
            path: '/orders/transfers',
        },
        {
            label: 'Transferts',
            path: '/orders/transfers',
        },
        {
            label: 'Création',
            path: '/orders/transfers',
        },
    ],
    '/orders/transfers/[id]': [
        {
            label: 'Services',
            path: '/orders/transfers',
        },
        {
            label: 'Transferts',
            path: '/orders/transfers',
        },
    ],
    '/orders/convoys': [
        {
            label: 'Services',
            path: '/orders/convoys',
        },
        {
            label: 'Convois',
            path: '/orders/convoys',
        },
    ],
    '/orders/convoys/create': [
        {
            label: 'Services',
            path: '/orders/convoys',
        },
        {
            label: 'Convois',
            path: '/orders/convoys',
        },
        {
            label: 'Création',
            path: '/orders/convoys',
        },
    ],
    '/orders/convoys/[id]': [
        {
            label: 'Services',
            path: '/orders/transfers',
        },
        {
            label: 'Convois',
            path: '/orders/transfers',
        },
    ],
    '/orders/embalming': [
        {
            label: 'Services',
            path: '/orders/embalming',
        },
        {
            label: 'Thanatopraxies',
            path: '/orders/embalming',
        },
    ],
    '/orders/embalming/create': [
        {
            label: 'Services',
            path: '/orders/embalming',
        },
        {
            label: 'Thanatopraxies',
            path: '/orders/embalming',
        },
        {
            label: 'Création',
            path: '/orders/embalming',
        },
    ],
    '/orders/embalming/[id]': [
        {
            label: 'Services',
            path: '/orders/transfers',
        },
        {
            label: 'Thanatopraxies',
            path: '/orders/transfers',
        },
    ],
    '/orders/procedures': [
        {
            label: 'Services',
            path: '/orders/procedures',
        },
        {
            label: 'Démarches',
            path: '/orders/procedures',
        },
    ],
    '/orders/procedures/create': [
        {
            label: 'Services',
            path: '/orders/procedures',
        },
        {
            label: 'Démarches',
            path: '/orders/procedures',
        },
        {
            label: 'Création',
            path: '/orders/procedures',
        },
    ],
    '/orders/procedures/[id]': [
        {
            label: 'Services',
            path: '/orders/transfers',
        },
        {
            label: 'Démarches',
            path: '/orders/transfers',
        },
    ],
    '/my-business/statisticals': [
        {
            label: 'Mon entreprise',
            path: '/my-business/statisticals',
        },
        {
            label: 'Statistiques',
            path: '/my-business/statisticals',
        },
    ],
    '/my-business/agencies': [
        {
            label: 'Mon entreprise',
            path: '/my-business/statisticals',
        },
        {
            label: 'Agences',
            path: '/my-business/agencies',
        },
    ],
    '/my-business/agencies/create': [
        {
            label: 'Mon entreprise',
            path: '/my-business/statisticals',
        },
        {
            label: 'Agences',
            path: '/my-business/agencies',
        },
        {
            label: 'Création',
            path: '/my-business/agencies',
        },
    ],
    '/my-business/agencies/[id]': [
        {
            label: 'Mon entreprise',
            path: '/my-business/statisticals',
        },
        {
            label: 'Agences',
            path: '/my-business/agencies',
        },
    ],

    '/my-business/agents': [
        {
            label: 'Mon entreprise',
            path: '/my-business/statisticals',
        },
        {
            label: 'Conseillers',
            path: '/my-business/agents',
        },
    ],
    '/my-business/agents/create': [
        {
            label: 'Mon entreprise',
            path: '/my-business/statisticals',
        },
        {
            label: 'Conseillers',
            path: '/my-business/agents',
        },
        {
            label: 'Création',
            path: '/my-business/agents',
        },
    ],
    '/my-business/agents/[id]': [
        {
            label: 'Mon entreprise',
            path: '/my-business/statisticals',
        },
        {
            label: 'Conseillers',
            path: '/my-business/agents',
        },
    ],
    '/my-business/pricing': [
        {
            label: 'Mon entreprise',
            path: '/my-business/statisticals',
        },
        {
            label: 'Gestion tarifaire',
            path: '/my-business/pricing',
        },
    ],
    '/my-business/settings': [
        {
            label: 'Mon entreprise',
            path: '/my-business/statisticals',
        },
        {
            label: 'Paramètres',
            path: '/my-business/settings',
        },
    ],
    '/my-profil/help': [
        {
            label: "Formulaire d'aide",
            path: '/my-business/statisticals',
        },
    ],
    '/my-profil/edit': [
        {
            label: 'Mon profil',
            path: '/my-business/statisticals',
        },
    ],
}
