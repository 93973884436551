import { findBreadcrumbByPath } from '@common/utils/helpers'
import { useGetFuneralQuery } from '@redux/features/funeral'
import { BREADCRUMBS as breadcrumbsList } from '@routes/index'
import { Breadcrumb } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

export default function Breadcrumbs() {
    const { pathname, query } = useRouter()

    const { data, isLoading } = useGetFuneralQuery(query?.id, {
        skip: !query?.id || pathname !== '/funerals/[id]',
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const BREADCRUMBS = React.useMemo(() => {
        const crumbs = findBreadcrumbByPath(pathname, breadcrumbsList)

        const items = []

        // Add crumbs with path finded
        crumbs.forEach((crumb) => {
            // If it's last we only show the label
            const isLastBreadcrumb = crumbs[crumbs.length - 1] === crumb

            items.push({
                component: (
                    <Breadcrumb.Item key={crumb.path} separator={<i className="fa-light fa-chevron-right" />}>
                        {isLastBreadcrumb && pathname !== '/funerals/[id]' ? <span>{crumb.label}</span> : <Link href={crumb.path}>{crumb.label}</Link>}
                    </Breadcrumb.Item>
                ),
            })
        })

        // If it's dynamic page create breadcrumb item and add it to the list
        switch (pathname) {
            case '/funerals/[id]':
                items.push({
                    component: (
                        <Breadcrumb.Item separator={<i className="fa-light fa-chevron-right" />}>
                            {data?.deceased_fullname} #{data?.id}
                        </Breadcrumb.Item>
                    ),
                })
                break
            default:
                break
        }

        return items
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, query?.id, isLoading])

    return (
        <Breadcrumb separator={<i className="fa-light fa-chevron-right" />}>
            <Breadcrumb.Item href="/" separator={<i className="fa-light fa-chevron-right" />}>
                <i className="fa-light fa-house" />
            </Breadcrumb.Item>

            {BREADCRUMBS?.map((breadcrumb, i) => (
                <React.Fragment key={i}>{breadcrumb?.component}</React.Fragment>
            ))}
        </Breadcrumb>
    )
}
