import { customerApi } from '@redux/rtk/api'
import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import addressBookReducer from './features/address-book/addressBookSlice'
import authReducer from './features/auth/authSlice'
import drawerReducer from './features/drawer/drawerSlice'
import funeralsReducer from './features/funeral/funeralsSlice'
import agenciesReducer from './features/my-business/agencies/agenciesSlice'
import settingsReducer from './features/my-business/settings/settingsSlice'
import statisticalsReducer from './features/my-business/statisticals/statisticalsSlice'
import tarificalReducer from './features/my-business/tarifical/tarificalSlice'
import selectReducer from './features/select/selectSlice'
import convoyReducer from './features/services/convoy/convoySlice'
import embalmingReducer from './features/services/embalming/embalmingSlice'
import proceduresReducer from './features/services/procedures/proceduresSlice'
import transfersReducer from './features/services/transfers/transfersSlice'
import agentsReducer from './features/users/agents/agentsSlice'

// If you need to omit some nested data, you need to set the key associate in root persist config
// and set the same key in the nested reducer
// Auth is an good example
// If you need more exmaple: ref to: https://github.com/rt2zz/redux-persist/issues/1110#issuecomment-548408907

const rootPersistConfig = {
    key: 'root',
    storage: storage,
    blacklist: [
        'funerals',
        'drawer',
        'transfers',
        'embalming',
        'procedures',
        'convoy',
        'agents',
        'agencies',
        'settings',
        'addressBook',
        'tarifical',
        'Procedure',
        'auth',
        'selectFilters',
        'statisticals',
        customerApi.reducerPath,
    ],
}

const authPersistConfig = {
    key: 'auth', // This key must be the same inside root reducer for nested fied and exclude them
    storage: storage,
    blacklist: ['editFields', 'loginFields', 'helpFields'],
}

const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authReducer),
    funerals: funeralsReducer,
    drawer: drawerReducer,
    transfers: transfersReducer,
    embalming: embalmingReducer,
    procedures: proceduresReducer,
    convoy: convoyReducer,
    agents: agentsReducer,
    agencies: agenciesReducer,
    settings: settingsReducer,
    tarifical: tarificalReducer,
    addressBook: addressBookReducer,
    selectFilters: selectReducer,
    statisticals: statisticalsReducer,
    [customerApi.reducerPath]: customerApi.reducer,
})

export const persistedRootReducer = persistReducer(rootPersistConfig, rootReducer)
