import { EMBALMING_CLOTHING_ON_SITE, EMBALMING_TYPES } from '@common/constants'
import { resetAddDataOrderForm, setAddDataOrderForm } from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
    addFields: {
        intervention: [
            {
                name: 'intervention_date',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY HH:mm', // IF BUG DATE CHECK HERE // TODO
            },
        ],
        additional_detail_center: [
            {
                name: 'type',
                label: 'Prestation',
                component: 'radio',
                options: EMBALMING_TYPES,
                labelCol: 24,
            },
            {
                name: 'pacemaker',
                label: 'Pacemaker',
                component: 'boolean',
                labelCol: 24,
            },
            {
                name: 'exhibition',
                label: 'Salon permanent',
                component: 'boolean',
                labelCol: 24,
            },
        ],
        additional_detail_right: [
            {
                name: 'clothing_on_site',
                label: 'Vêtements sur place',
                component: 'radio',
                options: EMBALMING_CLOTHING_ON_SITE,
                labelCol: 24,
            },
            {
                name: 'clothing_comments',
                placeholder: 'Où sont les vêtements ?',
                component: 'textaera',
                rows: 2,
                labelCol: 24,
            },
            {
                name: 'site_visit',
                label: 'Visite de la famille',
                component: 'boolean',
                labelCol: 24,
            },
        ],
        files: [
            {
                name: 'medias',
                component: 'file',
                labelCol: 24,
                maxSize: 4, // MB
                acceptedTypes: [
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'image/webm',
                    'application/pdf',
                ],
            },
        ],
    },

    editFields: {
        intervention: [
            {
                name: 'intervention_date',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY HH:mm',
            },
        ],
        additional_detail_center: [
            {
                name: 'type',
                label: 'Prestation',
                component: 'radio',
                options: EMBALMING_TYPES,
                labelCol: 24,
            },
            {
                name: 'pacemaker',
                label: 'Pacemaker',
                component: 'boolean',
                labelCol: 24,
            },
            {
                name: 'exhibition',
                label: 'Salon permanent',
                component: 'boolean',
                labelCol: 24,
            },
        ],
        additional_detail_right: [
            {
                name: 'clothing_on_site',
                label: 'Vêtements sur place',
                component: 'radio',
                options: EMBALMING_CLOTHING_ON_SITE,
                labelCol: 24,
            },
            {
                name: 'clothing_comments',
                placeholder: 'Où sont les vêtements ?',
                component: 'textaera',
                rows: 2,
                labelCol: 24,
            },
            {
                name: 'site_visit',
                label: 'Visite de la famille',
                component: 'boolean',
                labelCol: 24,
            },
        ],
        files: [
            {
                name: 'medias',
                component: 'file',
                labelCol: 24,
                maxSize: 4, // MB
                acceptedTypes: [
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'image/webm',
                    'application/pdf',
                ],
            },
        ],
    },

    addData: null,
}

const embalmingSlice = createSlice({
    name: 'embalming',
    initialState,
    reducers: {
        setAddDataForm: setAddDataOrderForm,
        resetAddDataForm: resetAddDataOrderForm,
    },
})

export const { setAddDataForm, resetAddDataForm } = embalmingSlice.actions
export default embalmingSlice.reducer

// Root selector
const embalmingSelector = (state) => state.embalming

export const selectEmbalmingFieldsAdd = createSelector(
    embalmingSelector,
    (embalmingState) => embalmingState.addFields
)

export const selectEmbalmingFieldsEdit = createSelector(
    embalmingSelector,
    (embalmingState) => embalmingState.editFields
)

export const selectEmbalmingAddData = createSelector(
    embalmingSelector,
    (embalmingState) => embalmingState.addData
)
