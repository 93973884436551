import { resetListActualFilter, setListActualFilter, setListPagination, setTotalInPagination } from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
    addFields: {
        informations: [
            {
                name: 'name',
                label: 'Nom',
                placeholder: "Nom de l'agence",
                component: 'text',
            },
            {
                name: 'phone',
                label: 'Tél.',
                placeholder: '01 02 03 04 05',
                component: 'phone',
            },
            {
                name: 'email',
                label: 'Email',
                placeholder: 'email@agence.com',
                component: 'text',
            },
        ],
        address: [
            {
                name: 'address',
                component: 'address',
                nestedFields: ['address', 'address_2', 'city', 'zipcode'],
            },
        ],
        ordersAccepted: [
            {
                name: 'can_book_service_transfer',
                label: 'Transferts',
                component: 'boolean',
            },
            {
                name: 'can_book_service_procedure',
                label: 'Démarches',
                component: 'boolean',
            },
            {
                name: 'can_book_service_embalming',
                label: 'Thanatopraxie',
                component: 'boolean',
            },
            {
                name: 'can_book_service_convoy',
                label: 'Convoi',
                component: 'boolean',
            },
        ],
    },

    editFields: {
        informations: [
            {
                name: 'name',
                label: 'Nom',
                placeholder: "Nom de l'agence",
                component: 'text',
            },
            {
                name: 'phone',
                label: 'Tél.',
                placeholder: '01 02 03 04 05',
                component: 'phone',
            },
            {
                name: 'email',
                label: 'Email',
                placeholder: 'email@agence.com',
                component: 'text',
            },
        ],
        address: [
            {
                name: 'address',
                component: 'address',
                nestedFields: ['address', 'address_2', 'city', 'zipcode'],
            },
        ],
        ordersAccepted: [
            {
                name: 'can_book_service_transfer',
                label: 'Transferts',
                component: 'boolean',
            },
            {
                name: 'can_book_service_procedure',
                label: 'Démarches',
                component: 'boolean',
            },
            {
                name: 'can_book_service_embalming',
                label: 'Thanatopraxies',
                component: 'boolean',
            },
            {
                name: 'can_book_service_convoy',
                label: 'Convois',
                component: 'boolean',
            },
        ],
    },

    filters: {
        fields: {
            fastSearch: [
                {
                    name: 'search',
                    placeholder: 'recherche rapide (agence, ville, …)',
                    component: 'search',
                    withStatus: false,
                },
            ],
        },
        actual: {},
    },

    pagination: {
        page: 1,
        size: 10,
        total: 0,
    },
}

const agentsSlice = createSlice({
    name: 'agencies',
    initialState,
    reducers: {
        setActualFilters: setListActualFilter,
        resetActualFilters: resetListActualFilter,
        // Pagination
        setPagination: setListPagination,
        setTotalLineToPagination: setTotalInPagination,
    },
})

export const { resetActualFilters, setActualFilters, setPagination, setTotalLineToPagination } = agentsSlice.actions
export default agentsSlice.reducer

// ROOT SELECTOR
const agenciesSelector = (state) => state.agencies

// FORM FIELDS
export const selectAgencyEditFields = createSelector(agenciesSelector, (agenciesState) => agenciesState.editFields)

export const selectAgencyAddFields = createSelector(agenciesSelector, (agenciesState) => agenciesState.addFields)

// FILTERS FIELDS
export const selectAgenciesFieldsFilters = createSelector(agenciesSelector, (agenciesState) => agenciesState.filters.fields)

// FILTERS SELECTED
export const selectAgenciesActualFilters = createSelector(agenciesSelector, (agenciesState) => agenciesState.filters.actual)

// PAGINATION
export const selectAgenciesPagination = createSelector(agenciesSelector, (agenciesState) => agenciesState.pagination)
