import { removeListFieldInFilter, resetListActualFilter, setListActualFilter, setListPagination, setTotalInPagination } from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/nextjs'

const initialState = {
    addFields: {
        account: [
            {
                name: 'username',
                label: 'Identifiant',
                placeholder: 'pmichel',
                component: 'text',
            },
        ],
        agent: [
            {
                name: 'firstname',
                label: 'Prénom',
                placeholder: 'Pierre',
                component: 'text',
            },
            {
                name: 'lastname',
                label: 'Nom',
                placeholder: 'Michel',
                component: 'text',
            },
            {
                name: 'email',
                label: 'Email',
                placeholder: 'mark@monagence.com',
                component: 'text',
            },
            {
                name: ['agent', 'phone'],
                label: 'Tél.',
                placeholder: '01 02 03 04 05',
                component: 'phone',
            },
        ],
        notification: [
            {
                name: ['agent', 'allow_mail_notifications'],
                label: 'Suivi des obsèques par email',
                component: 'boolean',
            },
        ],
        avatar: [
            {
                name: ['agent', 'avatar'],
                component: 'file',
                acceptedTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/webm'],
                maxSize: 4,
                multiple: false,
            },
        ],
        permissions: [
            {
                name: ['agent', 'can_loyalty_program'],
                label: 'Programme de fidélité',
                component: 'boolean',
            },
            {
                name: ['agent', 'can_see_prices'],
                label: 'Visualisation des prix',
                component: 'boolean',
            },
        ],
    },

    editFields: {
        agent: [
            {
                name: 'username',
                label: 'Identifiant',
                placeholder: 'pmichel',
                component: 'text',
                disabled: true,
            },
            {
                name: 'firstname',
                label: 'Prénom',
                placeholder: 'Mark',
                component: 'text',
            },
            {
                name: 'lastname',
                label: 'Nom',
                placeholder: 'Doe',
                component: 'text',
            },
            {
                name: 'email',
                label: 'Email',
                placeholder: 'mark@monagence.com',
                component: 'text',
            },
            {
                name: ['agent', 'phone'],
                label: 'Tél.',
                placeholder: '01 02 03 04 05',
                component: 'phone',
            },
        ],
        notification: [
            {
                name: ['agent', 'allow_mail_notifications'],
                label: 'Suivi des obsèques par email',
                component: 'boolean',
            },
        ],
        avatar: [
            {
                name: ['agent', 'avatar'],
                component: 'file',
                acceptedTypes: ['image/jpeg', 'image/png'],
                multiple: false,
            },
        ],
        permissions: [
            {
                name: ['agent', 'can_loyalty_program'],
                label: 'Programme de fidélité',
                component: 'boolean',
            },
            {
                name: ['agent', 'can_see_prices'],
                label: 'Visualisation des prix',
                component: 'boolean',
            },
            {
                name: 'is_active',
                label: 'Peut se connecter',
                component: 'boolean',
            },
        ],
    },

    filters: {
        fields: {
            drawer: [
                {
                    name: 'agency',
                    label: 'Agence',
                    labelCol: 24,
                    labelIcon: 'shop',
                    component: 'multiSelect',
                    placeholder: 'Selectionner des agences',
                    options: [],
                    withStatus: false,
                },
            ],
            fastSearch: [
                {
                    name: 'search',
                    placeholder: 'recherche rapide (prénom, nom, ville, …)',
                    component: 'search',
                    withStatus: false,
                },
            ],
        },
        actual: {},
    },

    pagination: {
        page: 1,
        size: 10,
        total: 0,
    },
}

const agentsSlice = createSlice({
    name: 'agents',
    initialState,
    reducers: {
        setOptionsToAgenciesField: (state, action) => {
            action.payload.fieldsType.forEach((fieldType) => {
                if (fieldType === 'filter') {
                    const agencyFieldIndex = state.filters.fields.drawer.findIndex((e) => e.name === 'agency')
                    if (agencyFieldIndex !== -1) {
                        state.filters.fields.drawer[agencyFieldIndex].options = action.payload.options
                    } else {
                        Sentry.captureException(new Error('-- agency -- field not found in filter fields (filter)'))
                    }
                }
            })
        },

        setActualFilters: setListActualFilter,
        resetActualFilters: resetListActualFilter,
        removeFieldInFilter: removeListFieldInFilter,

        // Pagination
        setPagination: setListPagination,
        setTotalLineToPagination: setTotalInPagination,
    },
})

export const {
    setOptionsToAgenciesField,

    setActualFilters,
    resetActualFilters,
    removeFieldInFilter,

    setPagination,
    setTotalLineToPagination,
} = agentsSlice.actions
export default agentsSlice.reducer

// Root selector
const agentsSelector = (state) => state.agents

export const selectAgentsFieldsFilters = createSelector(agentsSelector, (agentsState) => agentsState.filters.fields)

export const selectAgentsPagination = createSelector(agentsSelector, (agentsState) => agentsState.pagination)

export const selectAgentsFieldsAdd = createSelector(agentsSelector, (agentsState) => agentsState.addFields)

export const selectAgentsFieldsEdit = createSelector(agentsSelector, (agentsState) => agentsState.editFields)

export const selectAgentsActualFilters = createSelector(agentsSelector, (agentsState) => agentsState.filters.actual)

export const selectNumberOfActualFilters = createSelector(agentsSelector, (agentsState) => Object.keys(_.omit(agentsState.filters?.actual, ['search'])).length)
