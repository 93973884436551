import { createSelector, createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/nextjs'

const initialState = {
    editFields: {
        informations: [
            {
                name: 'username',
                label: 'Identifiant',
                component: 'text',
                disabled: true,
            },
            {
                name: 'firstname',
                label: 'Prénom',
                component: 'text',
            },
            {
                name: 'lastname',
                label: 'Nom',
                component: 'text',
            },
            {
                name: 'email',
                label: 'email',
                component: 'text',
            },
            {
                name: ['agent', 'phone'],
                label: 'Tel.',
                component: 'phone',
            },
        ],
        password: [
            {
                name: 'password',
                label: 'Nouveau ',
                component: 'password',
            },
            {
                name: 'password_confirm',
                label: 'Confirmer',
                component: 'password',
            },
        ],
        notification: [
            {
                label: 'Recevoir les notifications de suivi des obsèques par email',
                name: ['agent', 'allow_mail_notifications'],
                component: 'boolean',
                labelCol: 24,
            },
        ],
        avatar: [
            {
                name: ['agent', 'avatar'],
                component: 'file',
                acceptedTypes: ['image/jpeg', 'image/png'],
                multiple: false,
            },
        ],
    },

    loginFields: [
        {
            name: 'username',
            label: 'Identifiant',
            component: 'text',
        },
        {
            name: 'password',
            label: 'Mot de passe',
            component: 'password',
        },
    ],

    helpFields: [
        {
            name: 'funeral_id',
            label: 'Obseques',
            placeholder: 'Selectionner une obseque',
            component: 'select',
            options: [],
        },
        {
            name: 'message',
            label: 'Message',
            placeholder: 'Sujet',
            component: 'textaera',
            rows: 6,
        },
    ],

    isAuth: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuth: (state, action) => {
            state.isAuth = action.payload
        },
        setOptionsToFuneralField: (state, action) => {
            const funeralFieldIndex = state.helpFields.findIndex((e) => e.name === 'funeral_id')
            if (funeralFieldIndex !== -1) {
                state.helpFields[funeralFieldIndex].options = action.payload
            } else {
                Sentry.captureException(
                    new Error('-- funeral_id -- field not found in filter fields (helpFields)')
                )
            }
        },
    },
})

export const { setIsAuth, setOptionsToFuneralField } = authSlice.actions
export default authSlice.reducer

// Root selector
const authSelector = (state) => state.auth

export const selectAuthLoginFields = createSelector(
    authSelector,
    (authState) => authState.loginFields
)

export const selectHelpMeFields = createSelector(authSelector, (authState) => authState.helpFields)

export const selectAuthFieldsEdit = createSelector(
    authSelector,
    (authState) => authState.editFields
)

export const selectAuthIsAuth = createSelector(authSelector, (authState) => authState.isAuth)
