import { FUNERAL_DECEASED_TITLE, HAVE_BURY_DATE, ORDERS_STATUSES } from '@common/constants'
import { removeListFieldInFilter, resetListActualFilter, setListActualFilter, setListPagination, setTotalInPagination } from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import * as Sentry from '@sentry/nextjs'

const initialState = {
    addFields: {
        death: [
            {
                name: 'deceased_title',
                label: 'Civilité',
                placeholder: 'Selectionner une civilité',
                options: FUNERAL_DECEASED_TITLE,
                component: 'select',
            },
            {
                name: 'deceased_firstname',
                label: 'Prénom',
                component: 'text',
            },
            {
                name: 'deceased_lastname',
                label: 'Nom',
                component: 'text',
            },
            {
                name: 'death_date',
                label: 'Date du décès',
                component: 'date',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY',
            },
        ],

        agency: [
            {
                name: 'agency',
                label: 'Agence',
                placeholder: 'Selectionner une agence',
                component: 'select',
                options: [],
            },
        ],
        agent: [
            {
                name: 'agent',
                label: 'Conseiller',
                placeholder: 'Selectionner un conseiller',
                component: 'select',
                options: [],
            },
        ],

    },

    editFields: {
        death: [
            {
                name: 'deceased_title',
                label: 'Civilité',
                placeholder: 'Selectionner une civilité',
                options: FUNERAL_DECEASED_TITLE,
                component: 'select',
            },
            {
                name: 'deceased_firstname',
                label: 'Prénom',
                component: 'text',
            },
            {
                name: 'deceased_lastname',
                label: 'Nom',
                component: 'text',
            },
            {
                name: 'death_date',
                label: 'Date du décès',
                component: 'date',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY',
            },
        ],
        deceased: [
            {
                name: 'bury_date',
                label: 'Date du décès',
                component: 'date',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY',
                withStatus: false,
            },
            {
                name: 'bury_am_pm',
                label: 'Demi-journée',
                component: 'switch',
                checkedChildren: 'Matin',
                unCheckedChildren: 'Après-midi',
                withStatus: false,
            },
        ],

        agency: [
            {
                name: 'agency',
                label: 'Agence',
                placeholder: 'Selectionner une agence',
                component: 'select',
                options: [],
            },
        ],
        agent: [
            {
                name: 'agent',
                label: 'Conseiller',
                placeholder: 'Selectionner un conseiller',
                component: 'select',
                options: [],
            },
        ],
    },

    filters: {
        fields: {
            drawer: [
                {
                    name: 'bury_date',
                    component: 'dateRange',
                    label: 'Date de mise en bière',
                    labelCol: 24,
                    labelIcon: 'calendar-alt',
                    placeholder: ['Du', 'au'],
                    format: 'DD/MM/YYYY',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'have_bury_date',
                    component: 'radio',
                    options: HAVE_BURY_DATE,
                    // label: 'Date du décès',
                    // labelIcon: 'shop',
                    withStatus: false,
                    checkedChildren: 'Planifié',
                    unCheckedChildren: 'Non planifié',
                },
                {
                    name: 'agency',
                    label: 'Agences',
                    labelCol: 24,
                    labelIcon: 'shop',
                    component: 'multiSelect',
                    placeholder: 'Selectionner des agences',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'agent',
                    label: 'Conseillers',
                    labelCol: 24,
                    labelIcon: 'users',
                    component: 'multiSelect',
                    placeholder: 'Selectionner des conseillers',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'service_status',
                    label: 'Statuts des services',
                    labelCol: 24,
                    labelIcon: 'cube',
                    component: 'multiSelect',
                    placeholder: 'Selectionner des status',
                    options: ORDERS_STATUSES,
                    withStatus: false,
                },
                {
                    name: 'total_gross',
                    label: 'Montant',
                    labelCol: 24,
                    labelIcon: 'coins',
                    component: 'slider',
                    withStatus: false,
                    range: [0, 10000],
                },
            ],
            fastSearch: [
                {
                    name: 'search',
                    placeholder: 'recherche rapide (défunt, ville, …)',
                    component: 'search',
                    withStatus: false,
                },
            ],
        },
        actual: {},
    },

    pagination: {
        page: 1,
        size: 30,
        total: 0,
    },
}

const funeralsSlice = createSlice({
    name: 'funerals',
    initialState,
    reducers: {
        // Select options
        setOptionsToAgentField: (state, action) => {
            action.payload.fieldsType.forEach((fieldType) => {
                if (fieldType === 'filter') {
                    const agentFieldIndex = state.filters.fields.drawer.findIndex((e) => e.name === 'agent')
                    if (agentFieldIndex !== -1) {
                        state.filters.fields.drawer[agentFieldIndex].options = action.payload.options
                    } else {
                        Sentry.captureException(new Error('-- agent -- field not found in filter fields (filter)'))

                    }
                } else if (fieldType === 'add') {
                    const agentFieldIndex = state.addFields.agent.findIndex((e) => e.name === 'agent')
                    if (agentFieldIndex !== -1) {
                        state.addFields.agent[agentFieldIndex].options = action.payload.options
                    } else {
                        Sentry.captureException(new Error('-- agent -- field not found in add fields (addFields)'))
                    }
                } else if (fieldType === 'edit') {
                    const agentFieldIndex = state.editFields.agent.findIndex((e) => e.name === 'agent')
                    if (agentFieldIndex !== -1) {
                        state.editFields.agent[agentFieldIndex].options = action.payload.options
                    } else {
                        Sentry.captureException(new Error('-- agent -- field not found in edit fields (editFields)'))
                    }
                }
            })
        },
        setOptionsToAgenciesField: (state, action) => {
            action.payload.fieldsType.forEach((fieldType) => {
                if (fieldType === 'filter') {
                    const agencyFieldIndex = state.filters.fields.drawer.findIndex((e) => e.name === 'agency')
                    if (agencyFieldIndex !== -1) {
                        state.filters.fields.drawer[agencyFieldIndex].options = action.payload.options
                    } else {
                        Sentry.captureException(new Error('-- agency -- field not found in filter fields (filter)'))
                    }
                } else if (fieldType === 'add') {
                    const agencyFieldIndex = state.addFields.agency.findIndex((e) => e.name === 'agency')

                    if (agencyFieldIndex !== -1) {
                        state.addFields.agency[agencyFieldIndex].options = action.payload.options
                    } else {
                        Sentry.captureException(new Error('-- agency -- field not found in add fields (addFields)'))
                    }
                } else if (fieldType === 'edit') {
                    const agencyFieldIndex = state.editFields.agency.findIndex((e) => e.name === 'agency')
                    if (agencyFieldIndex !== -1) {
                        state.editFields.agency[agencyFieldIndex].options = action.payload.options
                    } else {
                        Sentry.captureException(new Error('-- agency -- field not found in edit fields (editFields)'))
                    }
                }
            })
        },
        // Filters
        setActualFilters: setListActualFilter,
        resetActualFilters: resetListActualFilter,
        removeFieldInFilter: removeListFieldInFilter,

        // Pagination
        setPagination: setListPagination,
        setTotalLineToPagination: setTotalInPagination,
    },
})

export const {
    setOptionsToAgentField,
    setOptionsToAgenciesField,
    removeFieldInFilter,
    resetActualFilters,

    setActualFilters,

    setPagination,
    setTotalLineToPagination,
} = funeralsSlice.actions

export default funeralsSlice.reducer

// Root selector
const funeralsSelector = (state) => state.funerals

export const selectFuneralsFieldsFilters = createSelector(funeralsSelector, (funeralsState) => funeralsState.filters.fields)

export const selectFuneralsActualFilters = createSelector(funeralsSelector, (funeralsState) => funeralsState.filters.actual)

export const selectFuneralsPagination = createSelector(funeralsSelector, (funeralsState) => funeralsState.pagination)

export const selectFuneralsPaginationParams = createSelector(funeralsSelector, (funeralsState) => _.pick(funeralsState.pagination, ['page', 'page_size']))

export const selectFuneralsFieldsAdd = createSelector(funeralsSelector, (funeralsState) => funeralsState.addFields)

export const selectFuneralsFieldsEdit = createSelector(funeralsSelector, (funeralsState) => funeralsState.editFields)

export const selectNumberOfActualFilters = createSelector(funeralsSelector, (funeralsState) => {
    const actualFilters = funeralsState.filters?.actual

    // Remove have_bury_date if his value is null
    if (_.isNil(actualFilters['have_bury_date'])) {
        return Object.keys(_.omit(actualFilters, ['search', 'ordering', 'have_bury_date'])).length
    } else {
        return Object.keys(_.omit(actualFilters, ['search', 'ordering'])).length
    }
})

export const selectFirstAgencyInOptions = createSelector(funeralsSelector, (funeralsState) => funeralsState.addFields.agency[0].options[0])
