import React from 'react'

const Flex = ({
    fullWidth,
    fullHeight,
    justify,
    align,
    direction = 'row',
    wrap,
    children,
    ...rest
}) => {
    return (
        <div
            {...rest}
            style={{
                justifyContent: getJustify(justify),
                alignItems: getAlign(align),
                display: 'flex',
                flexDirection: getDirection(direction),
                flexWrap: !!wrap,
                width: fullWidth ? '100%' : '',
                height: fullHeight ? '100%' : '',
                ...rest.style,
            }}
            children={children}
        />
    )
}

export default Flex

const getDirection = (direction) => {
    switch (direction) {
        case 'column':
            return 'column'
        case 'col':
            return 'column'
        case 'row':
            return 'row'
        default:
            return 'row'
    }
}

const getJustify = (justify) => {
    switch (justify) {
        case 'center':
            return 'center'
        case 'between':
            return 'space-between'
        case 'around':
            return 'space-around'
        case 'even':
            return 'space-even'
        case 'end':
            return 'flex-end'
        case 'start':
            return 'flex-start'
        case 'flex-end':
            return 'flex-end'
        case 'flex-start':
            return 'flex-start'
        case 'bottom':
            return 'flex-end'
        case 'top':
            return 'flex-start'
        default:
            return ''
    }
}

const getAlign = (align) => {
    switch (align) {
        case 'center':
            return 'center'
        case 'end':
            return 'flex-end'
        case 'start':
            return 'flex-start'
        case 'right':
            return 'flex-end'
        case 'left':
            return 'flex-start'
        default:
            return 'center'
    }
}
