import locale from 'antd/locale/fr_FR'

import theme from '../styles/antd-theme.json'

const ANTD_CONFIG = {
    locale,
    componentSize: 'large',
    theme: {
        token: theme,
    },
}

const DATE_FORMAT = {
    front: 'DD/MM/YYYY',
    api: 'YYYY-MM-DD',
    apiTime: 'HH:mm',
    apiWithTZ: 'YYYY-MM-DDTHH:mm:ssZ',
    apiWithMilliSecondTz: 'YYYY-MM-DD[T]HH:mm:ss.SSSZ',
    withSepator: (separator) => `DD${separator}MM${separator}YYYY`,
    withHourAndMinute: 'DD/MM/YYYY HH:mm',
    withHourMinuteText: 'DD/MM/YYYY à HH:mm',
    dateTime: 'DD/MM/YYYY HH:mm:ss',
    time: 'HH:mm',
}

const CALENDAR_CONFIG = {
    numberDateToDisplay: 7,
    dateFormatAPI: 'YYYY-MM-DD',
    dateFormatFRONT: 'DD-MM-YYYY',
    dateWithHourAndMinuteFormatAPI: 'YYYY-MM-DD HH:mm',
    dateWithHourMinuteTextFormat: 'YYYY-MM-DD à HH:mm',
}

const ORDERS_STATUSES = [
    {
        value: 'to_be_completed',
        label: 'A compléter',
    },
    {
        value: 'waiting',
        label: 'En attente',
    },
    {
        value: 'confirmed',
        label: 'Confirmé',
    },
    {
        value: 'finished',
        label: 'Terminé',
    },
    {
        value: 'blocking',
        label: 'Blocage',
    },
    {
        value: 'cancelled',
        label: 'Annulé',
    },
]

const FUNERAL_TYPES = [
    { label: 'Inhumation', value: 'burial' },
    { label: 'Crémation', value: 'cremation' },
    { label: 'Autre', value: 'other' },
]

const CEREMONY_TYPES = [
    { label: 'Civile', value: 'civil' },
    { label: 'Religieuse', value: 'religious' },
]

const FUNERAL_DECEASED_TITLE = [
    { value: 'mrs', label: 'Madame' },
    { value: 'mr', label: 'Monsieur' },
]

const ADMISSION_TO_BE_SIGNED = [
    { value: true, label: 'Oui' },
    { value: false, label: 'Non' },
]

const EMBALMING_TYPES = [
    { value: 'washing', label: 'Toilette' },
    { value: 'care', label: 'Soins' },
]

const EMBALMING_CLOTHING_ON_SITE = [
    { value: 'yes', label: 'Oui' },
    { value: 'no', label: 'Non' },
    { value: 'at_the_agency', label: "A l'agence" },
]

const ADDRESS_TYPE = [
    { value: 'church', label: 'Eglise' },
    { value: 'cemetery', label: 'Cimetière' },
    { value: 'airport', label: 'Aéroport' },
    { value: 'hospital', label: 'Hôpital' },
    { value: 'retirement_home', label: 'Maison de retraite' },
    { value: 'funeral_home', label: 'Funérarium' },
    { value: 'crematorium', label: 'Crématorium' },
    { value: 'town_hall', label: 'Mairie' },
]

const AUTHORITY_RELATION = [
    { value: 'father', label: 'Père' },
    { value: 'mother', label: 'Mère' },
    { value: 'son', label: 'Fils' },
    { value: 'daughter', label: 'Fille' },
    { value: 'brother', label: 'Frère' },
    { value: 'sister', label: 'Soeur' },
    { value: 'uncle', label: 'Oncle' },
    { value: 'aunt', label: 'Tante' },
    { value: 'newphew', label: 'Neveu' },
    { value: 'niece', label: 'Nièce' },
    { value: 'cousin', label: 'Cousin(e)' },
    { value: 'grandfather', label: 'Grand-père' },
    { value: 'grandmother', label: 'Grand-mère' },
    { value: 'step_father', label: 'Beau-père' },
    { value: 'step_mother', label: 'Belle-mère' },
    { value: 'step_brother', label: 'Beau-frère' },
    { value: 'step_sister', label: 'Belle-sœur' },
    { value: 'son_in_law', label: 'Gendre' },
    { value: 'daughter_in_law', label: 'Belle-fille' },
    { value: 'husband', label: 'Epoux' },
    { value: 'wife', label: 'Epouse' },
    { value: 'friend', label: 'Ami(e)' },
    { value: 'other', label: 'Autre' },
]

const PHONE_REGEX = /^((\+)33|0)[1-9](\s?\d{2}){4}$/g

const ORDER_TYPE = [
    {
        key: 'transfer',
        label: 'Transferts',
        route: 'transfers',
        agencyDetailKey: 'can_book_service_transfer',
    },
    {
        key: 'procedure',
        label: 'Démarches',
        route: 'procedures',
        agencyDetailKey: 'can_book_service_procedure',
    },
    {
        key: 'embalming',
        label: 'Thanatopraxies',
        route: 'embalmings',
        agencyDetailKey: 'can_book_service_embalming',
    },
    {
        key: 'convoy',
        label: 'Convois',
        route: 'convoys',
        agencyDetailKey: 'can_book_service_convoy',
    },
]

const HAVE_BURY_DATE = [
    { label: 'Planifié', value: true },
    { label: 'Non planifé', value: false },
    { label: 'Tous', value: '' },
]

const IS_SUPERVISOR = [
    { label: 'Oui', value: true },
    { label: 'Non', value: false },
    { label: 'Tous', value: '' },
]

const POLLING_INTERVAL = 30000

export {
    POLLING_INTERVAL,
    ANTD_CONFIG,
    DATE_FORMAT,
    CALENDAR_CONFIG,
    ORDERS_STATUSES,
    FUNERAL_TYPES,
    CEREMONY_TYPES,
    FUNERAL_DECEASED_TITLE,
    EMBALMING_TYPES,
    EMBALMING_CLOTHING_ON_SITE,
    ADDRESS_TYPE,
    AUTHORITY_RELATION,
    PHONE_REGEX,
    ORDER_TYPE,
    ADMISSION_TO_BE_SIGNED,
    HAVE_BURY_DATE,
    IS_SUPERVISOR,
}
