import { customerApi } from '@redux/rtk/api'

import { setTotalLineToPagination } from './funeralsSlice'
import * as Sentry from '@sentry/nextjs'

const funeralApi = customerApi.injectEndpoints({
    endpoints: (builder) => ({
        getFuneralsNoPage: builder.query({
            query: (params) => ({
                url: `customers/funerals/`,
                method: 'GET',
                params: {
                    no_page: true,
                    ...(params || {}),
                },
            }),
        }),

        getFunerals: builder.query({
            query: (params) => ({
                url: `customers/funerals/`,
                method: 'GET',
                params,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    dispatch(setTotalLineToPagination(data.count))
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
            providesTags: (result, error, arg) => (result ? [...result?.results?.map(({ id }) => ({ type: 'Funeral', id })), { type: 'Funeral', id: 'LIST' }] : [{ type: 'Funeral', id: 'LIST' }]),
        }),

        getFuneralsArchived: builder.query({
            query: (params) => ({
                url: `customers/funerals/archived/`,
                method: 'GET',
                params,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    dispatch(setTotalLineToPagination(data.count))
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
            providesTags: (result, error, arg) => (result ? [...result?.results?.map(({ id }) => ({ type: 'Funeral', id })), { type: 'Funeral', id: 'LIST_ARCHIVED' }] : [{ type: 'Funeral', id: 'LIST_ARCHIVED' }]),
        }),

        getFuneralHistories: builder.query({
            query: (id) => ({
                url: `customers/funerals/${id}/histories/`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Funeral', id: `${id}_HISTORIES` }],
        }),

        getFuneral: builder.query({
            query: (id) => ({
                url: `customers/funerals/${id}/`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Funeral', id }],
        }),

        getFuneralNotPlanified: builder.query({
            query: (params) => ({
                url: `customers/funerals/unplanned_count/`,
                method: 'GET',
                params,
            }),
        }),

        createFuneral: builder.mutation({
            query: (data) => ({
                url: 'customers/funerals/',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Funeral', id: 'LIST' }],
        }),

        editFuneral: builder.mutation({
            query: ({ data, id }) => ({
                url: `customers/funerals/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Funeral', id: arg.id }],
        }),

        deleteFuneral: builder.mutation({
            query: (id) => ({
                url: `customers/funerals/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Funeral', id: 'LIST' }],
        }),

        getFuneralPlanning: builder.query({
            query: (cursorDate) => ({
                url: `customers/funerals_planning/`,
                method: 'GET',
                params: {
                    start: cursorDate.start,
                    end: cursorDate.end,
                },
            }),
        }),
    }),

    overrideExisting: true,
})

export const { useGetFuneralsNoPageQuery, useGetFuneralHistoriesQuery, useGetFuneralNotPlanifiedQuery, useGetFuneralsQuery, useGetFuneralsArchivedQuery, useGetFuneralQuery, useCreateFuneralMutation, useEditFuneralMutation, useGetFuneralPlanningQuery, useDeleteFuneralMutation } = funeralApi
