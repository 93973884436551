
import { customerApi } from '@redux/rtk/api'
import * as Sentry from '@sentry/nextjs'

import { setTotalLineToPagination } from './agentsSlice'

const agentsApi = customerApi.injectEndpoints({
    endpoints: (builder) => ({
        getAgents: builder.query({
            query: (params) => ({
                url: `customers/agent_users/`,
                method: 'GET',
                params,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    dispatch(setTotalLineToPagination(data.count))
                } catch (error) {
                    Sentry.captureException(error)
                    
                }
            },
            providesTags: (result) => {
                return result
                    ? [
                          ...result?.results?.map(({ id }) => ({ type: 'Agent', id })),
                          { type: 'Agent', id: 'LIST' },
                      ]
                    : [{ type: 'Agent', id: 'LIST' }]
            },
        }),

        getAgentsNoPage: builder.query({
            query: (params) => ({
                url: `customers/agent_users/`,
                method: 'GET',
                params: {
                    no_page: true,
                    ...(params || {}),
                },
            }),
        }),

        getAgent: builder.query({
            query: (id) => ({
                url: `customers/agent_users/${id}/`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Agent', id }],
        }),

        // Used for common select field for get user associate to company
        getAgentsAgency: builder.query({
            query: (id) => ({
                url: `customers/agencies/${id}/agents/`,
                method: 'GET',
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => {
                return { ...response.data, ...response.status }
            },
            providesTags: (result) => {
                return result
                    ? [
                          ...result?.map(({ id }) => ({ type: 'AgentsAgency', id })),
                          { type: 'AgentsAgency', id: 'LIST' },
                      ]
                    : [{ type: 'AgentsAgency', id: 'LIST' }]
            },
        }),

        createAgent: builder.mutation({
            query: (body) => ({
                url: `customers/agent_users/`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Agent', id: 'LIST' }],
        }),

        updateAgent: builder.mutation({
            query: ({ data, id }) => ({
                url: `customers/agent_users/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Agent', id: arg.id }],
        }),

        deleteAgent: builder.mutation({
            query: (id) => ({
                url: `customers/agent_users/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Agent', id: arg.id }],
        }),

        deleteAvatar: builder.mutation({
            query: (id) => ({
                url: `customers/agent_users/${id}/avatar/`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Agent', id: arg.id }],
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetAgentsNoPageQuery,
    useGetAgentsQuery,
    useGetAgentQuery,
    useGetAgentsAgencyQuery,
    useCreateAgentMutation,
    useUpdateAgentMutation,
    useDeleteAgentMutation,
    useDeleteAvatarMutation,
} = agentsApi
