import { customerApi } from '@redux/rtk/api'

import { setIsAuth } from './authSlice'
import { persistor } from '@redux/store'
import * as Sentry from '@sentry/nextjs'

const authApi = customerApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: 'auth/agent/login/',
                method: 'POST',
                body: credentials,
            }),

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    localStorage.setItem(`funovia-token`, data.token)

                    dispatch(setIsAuth(true))

                    dispatch(authApi.endpoints.getMe.initiate())
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),
        logout: builder.mutation({
            query: () => ({
                url: 'auth/agent/logout/',
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    localStorage.removeItem(`funovia-token`)

                    dispatch(setIsAuth(false))

                    // Reset all endpoints
                    dispatch(customerApi.util.resetApiState())

                    // Reset all reducers
                    persistor.flush()
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),
        getMe: builder.query({
            query: () => ({
                url: 'auth/agent/me/',
                method: 'GET',
            }),
            providesTags: (result, error) => [{ type: 'Me' }],
        }),
        updateMe: builder.mutation({
            query: (data) => ({
                url: 'auth/agent/me/',
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: [{ type: 'Me' }],
        }),
        helpMe: builder.mutation({
            query: (data) => ({
                url: 'customers/user_help_form/',
                method: 'POST',
                body: data,
            }),
        }),
        deleteMeAvatar: builder.mutation({
            query: () => ({
                url: 'auth/agent/me/avatar/',
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Me' }], // Revalidate Me for getting new data about user
        }),
    }),
    overrideExisting: true,
})

export const {
    useLoginMutation,
    useLogoutMutation,
    useGetMeQuery,
    useUpdateMeMutation,
    useHelpMeMutation,
    useDeleteMeAvatarMutation,
} = authApi
