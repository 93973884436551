import { Divider, Select, Space, Spin, Typography } from 'antd'
import Link from 'next/link'
import React, { useState } from 'react'

const { Text, Title } = Typography
import Flex from '@common-components/blocs/Flex'
import { useGlobalSearchQuery } from '@redux/rtk/api'
import { setActualFilters } from '@redux/features/funeral'
import { useDispatch } from 'react-redux'

const SearchGlobal = () => {
    const [searchValue, setSearchValue] = useState('')
    const [optionsList, setOptionsList] = useState()
    const dispatch = useDispatch()

    const { data, isLoading } = useGlobalSearchQuery(
        { q: searchValue },
        {
            refetchOnMountOrArgChange: true,
            skip: !searchValue,
        }
    )

    React.useEffect(() => {
        setOptionsList([
            {
                title: 'Obsèques',
                icon: <i className="fa-light fa-folder" />,
                path: '/funerals/',
                list:
                    data?.funerals?.map((e) => ({
                        path: '/funerals/' + e.id,
                        id: e.id,
                        label: e.deceased_fullname,
                        description: '',
                        detail: '#' + e.id,
                    })) || [],
            },
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <div className="layoutSearchPage">
            <Select
                size="large"
                labelInValue
                allowClear={true}
                filterOption={false}
                showArrow={false}
                showSearch={true}
                onSearch={(e) => setSearchValue(e.trim())}
                searchValue={searchValue}
                notFoundContent={
                    optionsList?.length === 0 ? (
                        <>
                            <Title level={3}>Aucun contenu trouvé</Title>
                            <Text>Essayez autre chose</Text>
                        </>
                    ) : (
                        <>
                            <Title level={3}>
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                Effectuez une recherche sur l'ensemble de l'application.
                            </Title>
                            <br />
                            <Title level={4}>• Obsèques</Title>
                        </>
                    )
                }
                dropdownMatchSelectWidth={700}
                listHeight={'80vh'}
                popupClassName="searchPage"
                placeholder="Recherche globale"
            >
                {optionsList
                    ?.filter((e) => e?.list?.length)
                    ?.map((e, key) => (
                        <React.Fragment key={key}>
                            <Select.OptGroup
                                key={key + '-group'}
                                label={
                                    <>
                                        <Flex fullWidth justify="between">
                                            <Space align="center">
                                                {e.icon}
                                                <Title type="secondary" level={4}>
                                                    {e.title}
                                                </Title>
                                            </Space>
                                            {e.path && (
                                                <Link href={e.path}>
                                                    <a
                                                        onClick={(e) =>
                                                            dispatch(
                                                                setActualFilters({
                                                                    search: searchValue,
                                                                })
                                                            )
                                                        }
                                                    >
                                                        <Title type="secondary" level={5}>
                                                            Voir plus
                                                        </Title>
                                                    </a>
                                                </Link>
                                            )}
                                        </Flex>
                                        <Divider />
                                    </>
                                }
                            />
                            {e.list?.map((el, i) => (
                                <Select.Option key={key + '-' + i} onClick={() => serchRef?.current?.blur()}>
                                    <Link href={el.path || e.path}>
                                        <a>
                                            <Flex fullWidth justify="between">
                                                <Flex>
                                                    <Title level={4}>{el.label}</Title>
                                                    <Title className="search-detail" level={5}>
                                                        {el.detail}
                                                    </Title>
                                                </Flex>
                                                <Flex justify="end">
                                                    {el.description && (
                                                        <Text type="secondary" className="desc">
                                                            {el.description}
                                                        </Text>
                                                    )}
                                                    <i className="fa-light fa-chevron-right" />
                                                </Flex>
                                            </Flex>
                                        </a>
                                    </Link>
                                </Select.Option>
                            ))}
                        </React.Fragment>
                    ))}
            </Select>
            {isLoading ? <Spin /> : <i className="fa-light fa-search" />}
        </div>
    )
}

export default SearchGlobal
