import { customerApi } from '@redux/rtk/api'

import { setTotalLineToPagination } from './agenciesSlice'
import * as Sentry from '@sentry/nextjs'

const agenciesApi = customerApi.injectEndpoints({
    endpoints: (builder) => ({
        getAgencies: builder.query({
            query: (params) => ({
                url: `customers/agencies/`,
                method: 'GET',
                params,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    dispatch(setTotalLineToPagination(data.count))
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result?.results?.map(({ id }) => ({ type: 'Agency', id })),
                          { type: 'Agency', id: 'LIST' },
                      ]
                    : [{ type: 'Agency', id: 'LIST' }],

            transformResponse: (response) => {
                return response
            },
        }),
        getAgenciesNoPage: builder.query({
            query: (params) => ({
                url: `customers/agencies/`,
                method: 'GET',
                params: {
                    no_page: true,
                    ...(params || {}),
                },
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result?.map(({ id }) => ({ type: 'Agency', id })),
                          { type: 'Agency', id: 'LIST_NO_PAGE' },
                      ]
                    : [{ type: 'Agency', id: 'LIST_NO_PAGE' }],
        }),

        getAgency: builder.query({
            query: (id) => ({
                url: `customers/agencies/${id}/`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Agency', id }],
        }),

        createAgency: builder.mutation({
            query: (data) => ({
                url: `customers/agencies/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, params) => [{ type: 'Agency' }],
        }),

        updateAgency: builder.mutation({
            query: ({ data, id }) => ({
                url: `customers/agencies/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Agency', id: arg.id }],
        }),

        deleteAgency: builder.mutation({
            query: (id) => ({
                url: `customers/agencies/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Agency', id }],
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetAgenciesNoPageQuery,
    useGetAgenciesQuery,
    useGetAgencyQuery,
    useCreateAgencyMutation,
    useUpdateAgencyMutation,
    useDeleteAgencyMutation,
} = agenciesApi
