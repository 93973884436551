import {
    AUTHORITY_RELATION,
    FUNERAL_TYPES,
    ORDERS_STATUSES,
    CEREMONY_TYPES,
    EMBALMING_TYPES,
    ORDER_TYPE,
    DATE_FORMAT,
} from '../constants'

import dayjs from 'dayjs'
import _ from 'lodash'

const _removeCurrentPageIfFilterChange = (filters, pagation) => {
    // if (filter?.page && filter?.page !== pagination?.current) {
    //     return {
    //         current: 1,
    //     }
    // }
    // const formattedValues = Object.keys(filters).reduce((acc, key) => {
    //     console.log('filters[key]', filters[key])
    //     if ((!_.isEmpty(filters[key]) && filters[key]?.length > 0) || !_.isNil(filters[key])) {
    //         console.log('passing inside cond --', filters[key])
    //         // acc[key] = filters[key]
    //         pagation.page = 1
    //     }
    //     return acc
    // }, {})
    // console.log('form --', JSON.stringify(formattedValues, null, 4))
    // return {}
}

export const mergeFilterAndPagation = (filters, pagination) => {
    // const paginationFormatted = _removeCurrentPageIfFilterChange(filters, pagination)

    // console.log('-- filter', filters)
    // console.log('-- pagination', pagination)

    for (const [key, value] of Object.entries(filters)) {
        if (pagination.page !== 1 && filters['search'] !== '') {
            pagination.page = 1
        }
    }

    return {
        ...filters,
        ...pagination,
    }
}
/**
 * get form data from object
 * It's working on 2 level nested object
 * All treatment about form must be set in your handleSubmit function
 * inside !!! your page !!! before set to convertObjectToFormData
 * @example
 * const formProps = {
 *   firstname: 'John',
 *   lastname: 'Doe',
 *   agent: {
 *     phone: '123456789',
 *     allow_mail_notifications: true,
 *     avatar: null,
 *   }
 * }
 * @param {object} formProps
 * @param {('agent' | 'myKey')} nestedKeyName
 * @param {('.'|'[]')} nestedKeySeparator
 * @returns {object}
 */
export const convertObjectToFormData = (
    formProps,
    nestedKeyName = null,
    nestedKeySeparator = '.'
) => {
    const formData = new FormData()

    for (const [key, value] of Object.entries(formProps)) {
        // if value is null or undefined, we don't set it
        if (_.isNil(value)) {
            continue
        }

        if (value instanceof File) {
            formData.set(key, value)
        } else if (!_.isObject(value)) {
            formData.append(key, value)
        } else if (_.isArray(value)) {
            value?.forEach((val, index) => formData.set(`${key}[${index}]file`, val.originFileObj))
        } else {
            for (const [nestedDefaultKey, nestedDefaultValue] of Object.entries(value)) {
                // if nested value is null or undefined, we don't set it
                if (_.isNil(nestedDefaultValue)) continue

                if (nestedDefaultValue instanceof File) {
                    const key1 = `${nestedKeyName || key}${nestedKeySeparator}${nestedDefaultKey}`
                    formData.set(key1, nestedDefaultValue)
                } else {
                    formData.append(
                        `${nestedKeyName || key}${nestedKeySeparator}${nestedDefaultKey}`,
                        nestedDefaultValue
                    )
                }
            }
        }
    }
    return formData
}

/**
 * Convert files to File instance
 * If you need to select specific files, you need to set it where you call this function
 * He will be always returned an array of File instance because ANTD need to get array for display initial file even if you only have one file
 * @param {array} files
 * @returns array
 */
export const createFileInstanceFromFiles = (files) => {
    if (!_.isArray(files)) {
        // Sentry.captureException(new Error('createFileInstanceFiles: files must be an array'))
        // TODO: FIXME

        return []
    }

    // If files is empty, return empty array
    if (files.length === 0) return []

    return files.map((file) => {
        const { name, type } = file.originFileObj
        const fileInstance = new File([file], name, {
            type,
            lastModified: Date.now(),
            ...file.originFileObj,
        })

        return fileInstance
    })
}

/**
 * Format ordering for API
 * @param {string} columnDataIndex
 * @param {('ascend'|'descend')} order
 * @returns {('columnDataIndex'|'-columnDataIndex')}
 */
export const formatOrdering = (sorters) => {
    const sorterFormatted = sorters.reduce((acc, sorter) => {
        const { column, order } = sorter

        if (order === 'ascend') {
            acc['ordering'] = column?.sorter?.name
        } else if (order === 'descend') {
            acc['ordering'] = `-${column?.sorter?.name}`
        }

        return acc
    }, {})

    return sorterFormatted
}

export const formatTableSorterWithCurrentFilter = (sorter, currentFilter) => {
    if (Array.isArray(sorter)) {
        return {
            ...currentFilter,
            ...formatOrdering(sorter),
        }
    }

    return {
        ...currentFilter,
        ...formatOrdering([sorter]),
    }
}

export const findBreadcrumbByPath = (path, breadcrumbs) => {
    return breadcrumbs?.[path] || []
}

export const isEditableServiceFromStatus = (status) => {
    return status === 'to_be_completed' || status === 'waiting' || status === 'blocking'
}

/**
 * Filter order to display in the order dropdown in funeral detail
 * @param {*} agencyDetails
 * @returns (ORDER_TYPES) {array}
 */
export const filterOrderAllowed = (agencyDetails) => {
    const KEY_TO_SEARCH = [
        'can_book_service_convoy',
        'can_book_service_embalming',
        'can_book_service_procedure',
        'can_book_service_transfer',
    ]

    // Get key and value from object who contain T constant in his keys
    const orders = Object.keys(agencyDetails).reduce((acc, key) => {
        if (KEY_TO_SEARCH.some((keySearched) => key.includes(keySearched))) {
            acc[key] = agencyDetails[key]
        }
        return acc
    }, {})

    // Filters ORDER_TYPE who contain the key in his agencyDetailKey and who value is not false
    // Check ORDER_TYPE constant to see the result filtered
    // If current funeral can only book transfer and convoy, the result will be:
    // [ { key: 'transfer', label: 'Transfert', route: 'transfers', agencyDetailKey: 'can_book_service_transfer'},
    //   { key: 'convoy', label: 'Convois', route: 'convoys', agencyDetailKey: 'can_book_service_convoy' }]
    const orderAllowed = ORDER_TYPE.filter((order) => {
        return (
            KEY_TO_SEARCH.some((keySearched) => order.agencyDetailKey.includes(keySearched)) &&
            orders[order.agencyDetailKey] !== false
        )
    })

    // EXCLUDE agencyDetailKey from the result of each object
    return orderAllowed.map((order) => {
        const { agencyDetailKey, ...rest } = order
        return rest
    })
}

export const calculateMarginPriceNet = (buyingPriceNet, inputValue) => {
    return formatPricingValueToTwoNumber((buyingPriceNet * inputValue) / 100)
}

export const calculateSellingPriceGross = (buyingPriceNet, marginPercent, taxPercent) => {
    return formatPricingValueToTwoNumber(
        buyingPriceNet * (1 + marginPercent / 100) * (1 + taxPercent / 100)
    )
}

export const calculateMarginPercent = (buyingPriceNet, inputValue) => {
    return formatPricingValueToTwoNumber((inputValue / buyingPriceNet) * 100)
}

/**
 * Format pricing value to 2 number
 * @param {number} value
 * @returns number
 */
export const formatPricingValueToTwoNumber = (value) => {
    if (Number(value) === 0) return 0
    else return Number.parseFloat(value).toFixed(2)
}

/**
 * Convert datetime to api format
 * @param {dayjs} datetime
 * @returns datetime
 */
export const formatDatetimeToApi = (datetime) => {
    return dayjs(datetime).format(DATE_FORMAT.apiWithTZ)
}

/**
 * Convert datetime to front format
 * @param {dayjs} datetime
 * @returns datetime
 */
export const formatDatetimeToFront = (datetime) => {
    return dayjs(datetime).format(DATE_FORMAT.dateTime) // before DATE_FORMAT.dateTime
}

/**
 * Format agencies to select options
 * @param {array} agencies List of agencies
 * @param {boolean} withCompanyName Add company name to the label (default: true)
 * @returns
 */
export const formatAgenciesToSelectOptions = (agencies, withCompanyName = true) => {
    return agencies?.map((agency) => ({
        value: agency.id,
        label: agency.name + (withCompanyName ? ` (${agency.company_name || ''})` : ''),
    }))
}

export const formatPrice = (price, withStyle = true) => {
    if (withStyle) {
        return new Intl.NumberFormat('fr-FR', { currency: 'EUR', style: 'currency' }).format(price)
    }

    return new Intl.NumberFormat('fr-FR', { currency: 'EUR' }).format(price)
}

/**
 * Format route from type
 * @param {('transfer'|'embalming' | 'procedure' | 'convoy')} type
 * @param {number} orderID
 * @returns {string}
 */
export const getRouteOrderFromType = (type, orderID) => {
    const routeName = ORDER_TYPE.find((service) => service.key === type)?.route

    const routePath = `/orders/${routeName}/${orderID}`

    return routePath
}

/**
 * Get Label from type
 * @param {('transfer'|'embalming' | 'procedure' | 'convoy')} type
 * @returns {('Transfert' | 'Thanatopraxie' | 'Démarches' | 'Convois')}
 */
export const getLabelOrderFromType = (type) => {
    const label = ORDER_TYPE.find((service) => service.key === type)?.label
    return label
}

export const getEmbalmingFromType = (type) => {
    const label = EMBALMING_TYPES.find((embalming) => embalming.value === type)?.label
    return label
}

export const getCeremonyType = (ceremonyType) => {
    return CEREMONY_TYPES.find((type) => type.value === ceremonyType)?.label
}

export const getFuneralType = (funeralType) => {
    return FUNERAL_TYPES.find((type) => type.value === funeralType)?.label
}

export const getRelationType = (relationType) => {
    return AUTHORITY_RELATION.find((type) => type.value === relationType)?.label
}

/**
 * Get status name from key
 * @param {('to_be_completed' | 'waiting' | 'confirmed' | 'finished' | 'blocking' | 'cancelled')} status
 * @returns {('A compléter' | 'En attente' | 'Confirmé' | 'Terminé' | 'Blocage' | 'Annulé')}
 */
export const getStatusOrder = (status) => {
    const statusName = ORDERS_STATUSES.find((s) => s.value === status)?.label
    return statusName
}

/**
 * Convert breakline to <br />
 * @param {string} str
 * @returns {string}
 */
export const nl2br = (str) => {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
}
